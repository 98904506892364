import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import imgAlwitra from "../assets/partner/alwitra.png"
import imgArag from "../assets/partner/arag.png"
import imgBauder from "../assets/partner/bauder.png"
import imgBmi from "../assets/partner/bmiPartner.png"
import imgBraas from "../assets/partner/bmiBraas.png"
import imgDEG from "../assets/partner/deg.png"
import imgEnke from "../assets/partner/enke.png"
import imgEternit from "../assets/partner/eternit.png"
import imgFOS from "../assets/partner/fos.png"
import imgGallhoefer from "../assets/partner/gallhoefer.png"
import imgIcopal from "../assets/partner/bmiIcopal.png"
import imgJanssen from "../assets/partner/janssen.jpg"
import imgMeinDach from "../assets/partner/meindach.png"
import imgPrefa from "../assets/partner/prefa.png"
import imgProvinzial from "../assets/partner/provinzial.jpg"
import imgRathscheck from "../assets/partner/rathscheck.png"
import imgReca from "../assets/partner/reca.png"
import imgRheinzink from "../assets/partner/rheinzink.png"
import imgRoto from "../assets/partner/roto.png"
import imgSignalIduna from "../assets/partner/signal_iduna.png"
import imgVedag from "../assets/partner/bmiVedag.png"
import imgVelux from "../assets/partner/velux.png"
import imgWolfin from "../assets/partner/bmiWolfin.png"
import imgWuerth from "../assets/partner/wuerth.png"
import imgHaushaut from "../assets/partner/haushaut.png"
import imgTierschutz from "../assets/partner/tierschutzverein_dormagen.png"

export default class List_Partner extends Component {
  render() {
    const settings = {
      className: "slider",
      arrows: false,
      autoplay: true,
      autoplaySpeed: 800,
      centerMode: true,
      centerPadding: "60px",
      dots: false,
      infinite: true,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 800,
      variableWidth: true,
    }
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img
              src={imgBmi}
              alt="BMI System Partner"
              className="partner-pic"
            />
          </div>
          <div>
            <a href="https://www.wuerth.de/">
              <img src={imgWuerth} alt="Wuerth" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.braas.de/">
              <img src={imgBraas} alt="Braas" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.icopal.de/">
              <img src={imgIcopal} alt="Icopal" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.vedag.de/">
              <img src={imgVedag} alt="Vedag" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.wolfin.de/">
              <img src={imgWolfin} alt="Wolfin" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://alwitra.de/">
              <img src={imgAlwitra} alt="Alwitra" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.arag.de/">
              <img src={imgArag} alt="ARAG" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.bauder.de">
              <img src={imgBauder} alt="Bauder" className="partner-pic" />
            </a>
          </div>

          <div>
            <a href="https://www.deg-dach.de/">
              <img src={imgDEG} alt="DEG" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.enke-werk.de/">
              <img src={imgEnke} alt="Enke" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.eternit.at/">
              <img src={imgEternit} alt="Eternit" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.fos.de/de/">
              <img src={imgFOS} alt="FOS" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://melle-gallhoefer.de/gallhoefer/">
              <img
                src={imgGallhoefer}
                alt="Gallhoefer"
                className="partner-pic"
              />
            </a>
          </div>
          <div>
            <a href="https://www.haushaut.de/">
              <img src={imgHaushaut} alt="Haushaut" className="partner-pic" />
            </a>
          </div>

          <div>
            <a href="http://www.janssen-beda.de/">
              <img src={imgJanssen} alt="Janssen" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.meindach.de/">
              <img src={imgMeinDach} alt="MeinDach" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.prefa.de/">
              <img src={imgPrefa} alt="Prefa" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.provinzial.de/">
              <img
                src={imgProvinzial}
                alt="Provinzial"
                className="partner-pic"
              />
            </a>
          </div>
          <div>
            <a href="https://www.rathscheck.de/">
              <img
                src={imgRathscheck}
                alt="Rathscheck"
                className="partner-pic"
              />
            </a>
          </div>
          <div>
            <a href="https://www.recanorm.de/">
              <img src={imgReca} alt="Recanorm" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.rheinzink.de/">
              <img src={imgRheinzink} alt="Rheinzink" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="http://www.roto-frank.com/de/">
              <img src={imgRoto} alt="ROTO" className="partner-pic" />
            </a>
          </div>
          <div>
            <a href="https://www.signal-iduna.de/">
              <img
                src={imgSignalIduna}
                alt="Signal Iduna"
                className="partner-pic"
              />
            </a>
          </div>
          <div>
            <a href="https://tierheimdormagen.de/">
              <img
                src={imgTierschutz}
                alt="Tierheim Dormagen"
                className="partner-pic"
              />
            </a>
          </div>
          <div>
            <a href="https://www.velux.de/">
              <img src={imgVelux} alt="VELUX" className="partner-pic" />
            </a>
          </div>
        </Slider>
      </div>
    )
  }
}
